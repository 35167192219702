<script lang="ts" setup>
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { ModalType, useUiStore } from '@/store/ui';
import ItemAddModal from '@/components/admin/itemAddModal.vue';
import ItemShowModal from '@/components/itemShowModal.vue';
import ItemUpdateModal from '@/components/admin/itemUpdateModal.vue';
import AuthLogin from '@/components/admin/authLogin.vue';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/ui/drawer';

const uiStore = useUiStore();

// Map modal types to components
const modalMap = {
  [ModalType.ITEM_ADD]: ItemAddModal,
  [ModalType.ITEM_SHOW]: ItemShowModal,
  [ModalType.ITEM_UPDATE]: ItemUpdateModal,
  [ModalType.AUTH_LOGIN]: AuthLogin
};

// Dynamically resolve the modal component
const DialogBody = computed(() => modalMap[uiStore.state.modal.type as ModalType]);

</script>

<template>
  <Drawer v-if="uiStore.state.modal.isDrawer" :open="uiStore.state.modal.isOpen"
          @update:open="(event) => !event && uiStore.actions.closeModal()">
    <DrawerContent class="h-[calc(100dvh-3%)]">
      <DrawerHeader class="hidden">
        <DrawerTitle>Are you absolutely sure?</DrawerTitle>
        <DrawerDescription>This action cannot be undone.</DrawerDescription>
      </DrawerHeader>

      <DialogBody />

<!--      <DrawerFooter>-->
<!--        <Button>Submit</Button>-->
<!--        <DrawerClose>-->
<!--          <Button variant="outline">-->
<!--            Cancel-->
<!--          </Button>-->
<!--        </DrawerClose>-->
<!--      </DrawerFooter>-->
    </DrawerContent>
  </Drawer>

  <Dialog v-else :open="uiStore.state.modal.isOpen"
          @update:open="(event) => event == false && uiStore.actions.closeModal()">
    <DialogContent class="overflow-hidden">
      <DialogHeader>
        <DialogTitle>{{ uiStore.state.modal.props.title }}</DialogTitle>
        <DialogDescription v-show="uiStore.state.modal.props.description">{{
            uiStore.state.modal.props.description || uiStore.state.modal.props.title
          }}
        </DialogDescription>
      </DialogHeader>

      <DialogBody />

      <DialogFooter id="dialog-footer">

      </DialogFooter>

      <transition name="fade">
        <div v-if="uiStore.state.modal.isLoading"
             class="bg-white/70 flex items-center justify-center absolute top-0 left-0 w-full h-full backdrop-blur-sm">
          <Icon class="animate-spin" name="tabler:loader-2" size="40" />
        </div>
      </transition>
    </DialogContent>
  </Dialog>
</template>