import { default as categoriesQSSd85CmF9Meta } from "/opt/buildhome/repo/pages/admin/categories.vue?macro=true";
import { default as codesO9dC8RGKd2Meta } from "/opt/buildhome/repo/pages/admin/codes.vue?macro=true";
import { default as customersf2pnlARsC7Meta } from "/opt/buildhome/repo/pages/admin/customers.vue?macro=true";
import { default as itemsvxpSgIZY0EMeta } from "/opt/buildhome/repo/pages/admin/items.vue?macro=true";
import { default as menuAXBnGyRFPJMeta } from "/opt/buildhome/repo/pages/admin/menu.vue?macro=true";
import { default as ordersQNafub9gBAMeta } from "/opt/buildhome/repo/pages/admin/orders.vue?macro=true";
import { default as indexKczziSTLdaMeta } from "/opt/buildhome/repo/pages/admin/settings/theme/index.vue?macro=true";
import { default as indexIbNDbq129sMeta } from "/opt/buildhome/repo/pages/app/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
export default [
  {
    name: "admin-categories",
    path: "/admin/categories",
    meta: categoriesQSSd85CmF9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/admin/categories.vue")
  },
  {
    name: "admin-codes",
    path: "/admin/codes",
    meta: codesO9dC8RGKd2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/admin/codes.vue")
  },
  {
    name: "admin-customers",
    path: "/admin/customers",
    meta: customersf2pnlARsC7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/admin/customers.vue")
  },
  {
    name: "admin-items",
    path: "/admin/items",
    meta: itemsvxpSgIZY0EMeta || {},
    component: () => import("/opt/buildhome/repo/pages/admin/items.vue")
  },
  {
    name: "admin-menu",
    path: "/admin/menu",
    meta: menuAXBnGyRFPJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/admin/menu.vue")
  },
  {
    name: "admin-orders",
    path: "/admin/orders",
    meta: ordersQNafub9gBAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/admin/orders.vue")
  },
  {
    name: "admin-settings-theme",
    path: "/admin/settings/theme",
    component: () => import("/opt/buildhome/repo/pages/admin/settings/theme/index.vue")
  },
  {
    name: "app",
    path: "/app",
    meta: indexIbNDbq129sMeta || {},
    component: () => import("/opt/buildhome/repo/pages/app/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  }
]