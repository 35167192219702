export enum ModalType {
  ITEM_ADD = 'itemAdd',
  ITEM_SHOW = 'itemShow',
  ITEM_UPDATE = 'itemUpdate',
  AUTH_LOGIN = 'authLogin'
}

export type ModalProps = {
  title: string;
  description?: string;
  data?: any;
}

type Modal = {
  isOpen: boolean;
  isDrawer?: boolean;
  isLoading: boolean;
  type: ModalType | null;
  props: ModalProps;
}

type Settings = {
  colors_primary: string;
  colors_secondary: string;
}

type UiState = {
  modal: Modal,
  settings: Settings
}

export const useUiStore = defineStore('ui', () => {
  const state = ref<UiState>({
    modal: {
      isOpen: false,
      isDrawer: false,
      isLoading: false,
      type: null,
      props: {} as ModalProps
    },
    settings: {} as Settings
  });

  const getters = {};


  const actions = {
    openModal(type: ModalType, props: ModalProps, isDrawer?: boolean) {
      state.value.modal.isOpen = true;
      state.value.modal.type = type;
      state.value.modal.props = props;
      state.value.modal.isDrawer = isDrawer ?? false;
    },
    closeModal() {
      state.value.modal.isOpen = false;
      state.value.modal.props = {} as ModalProps;
      state.value.modal.isDrawer = false;
    }

    // Fetch basic app settings
    /*async fetchSettings() {
      try {
        const { data } = await useFetch('/api/settings');

        console.log(data.value.data)
        if (data.value?.success) {
          state.settings = data.value.data as Settings;
        }

      } catch (error) {
        console.error(error);
      }
    }*/
  };

  return { state, getters, actions };
});