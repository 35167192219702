import { z } from 'zod';

export const ItemSchema = z.object({
    title: z.string().min(5, 'Have to be 5 characters at minimum.'),
    description: z.string().min(25, 'The description have to be more larger.'),
    active: z.boolean({
        invalid_type_error: 'Active has to be a Boolean',
        required_error: 'Active status is required'
    }),
    price: z.number().min(1),
    promotionalPrice: z.number().min(1),
    authorId: z.number({message: 'Author has to be selected'}),
    categoryId: z.number({message: 'Category has to be selected'})
});