export default defineNuxtPlugin(() => {
  const formatCurrency = (value: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value / 100);

  const formatDiscount = (originalValue: number, promotionalValue: number) => {
    const percentage = ((originalValue - promotionalValue) / originalValue) * 100;
    return percentage.toFixed(0);
  };

  return {
    provide: {
      formatCurrency,
      formatDiscount
    }
  };
});
