<script lang="ts" setup>
/**
 * TODO: - Waiter table management
 * TODO: - initial page with presentation, wifi password and informations.
 * TODO: - Add ability to generate a custom QR Code
 * TODO: - Read a QR code and set the table number
 * TODO: - Integrate with Mercado Pago API
 * TODO: - Add delivery management
 */
import chroma from 'chroma-js';
import { useAuthStore } from '@/store/auth';

const runtimeConfig = useRuntimeConfig();

const authStore = useAuthStore();

// const theme = uiStore.state.settings;

// Server init
// await useAsyncData('settings', () => uiStore.actions.fetchSettings());

// Getting user information
if (useCookie(`#${runtimeConfig.public.APP_ID}-authorization`).value) {
  await useAsyncData('user', () => authStore.actions.fetchUser());
}


// Setting app theme
useHead({
  style: [
    {
      textContent() {
        return `:root {
          --primary-color: #616163;
          --secondary-color: #ffcb29;
          --darken-secondary-color: ${chroma('#ffcb29').darken(2).hex()}
        }
      `;
      }
    }
  ]
});

</script>
<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <Modal />
  </div>
</template>

<style lang="postcss">
body {
  @apply bg-zinc-100;
}
</style>