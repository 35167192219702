<script lang="ts" setup>
import { toTypedSchema } from '@vee-validate/zod';
import { ItemSchema } from '@/schemas/itemSchema';
import { useForm } from 'vee-validate';
import { useUiStore } from '@/store/ui';
import type { Item } from '@prisma/client';
import type { ApiResponse } from '@/types/api-response';


const uiStore = useUiStore();
const formSchema = toTypedSchema(ItemSchema);
const form = useForm({
  validationSchema: formSchema
});

const itemUpdateForm = useTemplateRef('item-update-form')

const itemId = uiStore.state.modal.props.data;

onMounted(() => {
  uiStore.state.modal.isLoading = true;
});

watchEffect(async () => {
  const response = await $fetch<ApiResponse<Item>>('/api/items/' + itemId);

  if (response) {
    setTimeout(() => {
      uiStore.state.modal.isLoading = false;
    }, 100);
    form.setFieldValue('title', response.data.title);
    form.setFieldValue('description', response.data.description);
    form.setFieldValue('active', response.data.active);
    form.setFieldValue('authorId', response.data.authorId);
  }

})

/**
 * @todo - The user can't create an item with an existing name
 * @todo - Add a toast package to show feedback for users
 */
const updateItem = form.handleSubmit(async (values) => {
  console.log('Form submitted!', values);
  await $fetch('/api/items/' + itemId, {
    method: 'PUT',
    body: values
  }).then(async () => {
    uiStore.actions.closeModal();
    await refreshNuxtData();
  }).catch(error => {
    console.error(error);
  });
});

/**
 * @todo - Add an alert dialog to confirm the delete action
 */
const deleteItem = async () => {
  await $fetch('/api/items/' + itemId, {
    method: 'DELETE'
  }).then(async () => {
    uiStore.actions.closeModal();
    await refreshNuxtData('items');
  }).catch(error => {
    console.error(error);
  })
}

const triggerSubmit = () => {
  itemUpdateForm.value?.requestSubmit();
}

</script>

<template>
  <div>
    <form ref="item-update-form" @submit="updateItem">
      <FormField v-slot="{ componentField }" name="title">
        <FormItem>
          <FormLabel>Title</FormLabel>
          <FormControl>
            <Input placeholder="Item title" type="text" v-bind="componentField" />
          </FormControl>
          <!--              <FormDescription>-->
          <!--                This is your public display name.-->
          <!--              </FormDescription>-->
          <FormMessage />
        </FormItem>
      </FormField>
      <FormField v-slot="{ componentField }" name="description">
        <FormItem>
          <FormLabel>Description</FormLabel>
          <FormControl>
            <Input placeholder="Item description" type="text" v-bind="componentField" />
          </FormControl>
          <!--              <FormDescription>-->
          <!--                This is your public display name.-->
          <!--              </FormDescription>-->
          <FormMessage />
        </FormItem>
      </FormField>

      <Teleport defer to="#dialog-footer">
        <Button variant="destructive" @click="deleteItem">
          Delete
        </Button>

        <Button @click="triggerSubmit">
          Submit
        </Button>
      </Teleport>
    </form>
  </div>
</template>