import { useUiStore } from '@/store/ui';

interface AuthState {
  isLoggedIn: boolean;
  user: object;
}

export const useAuthStore = defineStore('auth', () => {
  const runtimeConfig = useRuntimeConfig();
  const uiStore = useUiStore();

  const state = ref<AuthState>({
    isLoggedIn: false,
    user: {}
  });

  const getters = {};

  const actions = {
    async login() {
      try {
        const response = await $fetch('/api/auth/login', {
          method: 'POST',
          body: {
            email: 'dygaomail@gmail.com',
            password: 'senha123'
          }
        });

        console.log('USER: ', response);

        if (response.success) {

          // Math of expiration date
          const expirationTime = new Date(Date.now() + (response.data.expires_in * 5000));


          // Defining user authorization cookie
          useCookie(`#${runtimeConfig.public.APP_ID}-authorization`, { expires: expirationTime }).value = response.data.access_token;

          // Closing auth modal
          uiStore.actions.closeModal();

          // Getting user information
          await actions.fetchUser();

        }

      } catch (error) {
        console.error(error);
      }
    },
    async fetchUser() {

      const authorizationToken = useCookie(`#${runtimeConfig.public.APP_ID}-authorization`).value;

      if (authorizationToken) {

        // Fetching user information
        // @todo create an auth system
        const user = await $fetch('/api/auth/me', {
          headers: {
            'authorization': `Bearer ${authorizationToken}`
          }
        });

        // @todo - Create a auth system
        const foodShowUser = await $fetch('/api/user/me', {
          headers: {
            'authorization': `Bearer ${authorizationToken}`
          }
        });

        console.log(user)
        console.log(foodShowUser)

        if (user) {
          console.log(user);
          state.value.user = user as object;
          state.value.user.foodShowUser = foodShowUser.data;
          state.value.isLoggedIn = true;
        }

        return state.value.user;
      }

    },
    logout() {
      useCookie(`#${runtimeConfig.public.APP_ID}-authorization`).value = '';
    }
  };

  return { state, getters, actions };
});