<script lang="ts" setup>
import { toTypedSchema } from '@vee-validate/zod';
import { ItemSchema } from '@/schemas/itemSchema';
import { useForm } from 'vee-validate';
import { useUiStore } from '@/store/ui';
import type { ApiResponse } from '@/types/api-response';
import type { Item } from '@prisma/client';
import { useAuthStore } from '@/store/auth';

const uiStore = useUiStore();
const authStore = useAuthStore();
const formSchema = toTypedSchema(ItemSchema);

const form = useForm({
  validationSchema: formSchema,
  initialValues: {
    active: true,
    authorId: 1,
    categoryId: 1
  }
});

/**
 * @todo - The user can't create an item with an existing name
 * @todo - Add a toast package to show feedback for users
 * @todo - Handle server error when is submitted
 * @todo - Handle form errors and show the errors on client side with VeeValidade
 * @todo - Create a way to the user select the category
 */
const onSubmit = form.handleSubmit(async (values) => {
  console.log('Form submitted!', values);

  try {
    const response = await $fetch<ApiResponse<Item>>('/api/items', {
      method: 'POST',
      body: {
        ...values,
        authorId: authStore.state.user.foodShowUser.id,
        categoryId: 16 // Brunch category
      }
    });

    if (response.success) {
      uiStore.actions.closeModal();
      await refreshNuxtData('items');
    } else {
      console.error(response);
    }

  } catch (error) {
    console.error(error);
  }

});

</script>

<template>
  <div>
    <form @submit="onSubmit">
      <FormField v-slot="{ componentField }" name="title">
        <FormItem>
          <FormLabel>Title</FormLabel>
          <FormControl>
            <Input placeholder="Item title" type="text" v-bind="componentField" />
          </FormControl>
          <!--              <FormDescription>-->
          <!--                This is your public display name.-->
          <!--              </FormDescription>-->
          <FormMessage />
        </FormItem>
      </FormField>
      <FormField v-slot="{ componentField }" name="description">
        <FormItem>
          <FormLabel>Description</FormLabel>
          <FormControl>
            <Input placeholder="Item description" type="text" v-bind="componentField" />
          </FormControl>
          <!--              <FormDescription>-->
          <!--                This is your public display name.-->
          <!--              </FormDescription>-->
          <FormMessage />
        </FormItem>
      </FormField>
      <Button type="submit">
        Submit
      </Button>
    </form>
  </div>
</template>